.card {

    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
background-color: #333;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
        color: var(--color-primary);

        i{
            padding-right: 7px;
        }
    }
    .card-body{
        img {
            margin: 0 auto;
            padding: 10px 90px;
        }

        ul:last-child{
            margin-bottom: 0;
        }
    }
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);
}
