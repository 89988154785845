
.btn-primary:not(:disabled):not(.disabled) {
    background-color: var(--color-primary);
    font-weight: bold;
    color: #fff;

    &:hover {
        background-color: transparentize($color-primary, 0.15);
        color: #fff;
    }

    &:focus {
        background-color: #fff;
        font-weight: bold;
        color: var(--color-primary);
    }
}

a.action-edit, a.action-detail {
    background-color: var(--color-primary);
    color: #fff;
}

.custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}