body {
    background-color: $body-background-color;

    .wrapper {
        margin: 0;
        width: 100%;
        @media (min-width: 1280px) {

            grid-column-gap: 5px;
        }


        .content-wrapper {
            margin-top: 0px;
            @media (min-width: 1024px) {
                grid-template-columns: 15px auto 15px;
            }
        }
    }

    .row {
        &.top-buffer {
            margin-top: 15px;
        }
    }
}

.content-header {
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);
    padding: 10px 20px;
    background-color: #fff;
    margin-bottom: 20px;

    .content-header-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

#sidebar-resizer-handler {
    width: 15px;
    grid-area: sidebar-resizer-handler;
    z-index: 9999;
}

.content-wrapper {
    padding-bottom: 15px;
}

@media (min-width: 1024px) {
    .content-panel-body {
        padding: 25px;
    }
}

.content-panel-body {
    padding: 25px;
    border-radius: var(--border-radius);
}
