.tableauPlaceholder {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: block;
}

.tableauViz {
    display: block;
    margin: 0px;
    padding: 0px;
    border: none;
    width: 100%;
    height: 300px;
}