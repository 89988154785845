.ea-sidebar-width-compact {
	aside.main-sidebar {
		width: 50px;

		.sidebar-menu li {
			padding: 10px 40px 10px 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			@include transition(all 100ms ease-in-out);

			&.active {
				right: 0px;
				width: calc(100% + 5px);
			}

			&:hover {
				min-width: 175px;
			}
			&:hover {
				box-shadow: var(--box-shadow-lg);
				background: #333;

				&:not(.active) a {
					color: var(--color-primary);

					i {
						color: var(--color-primary);
					}

					span {
						color: var(--color-primary);
						top: 4px;
						left: 40px;
						font-weight: bold;
						background: transparent;
					}
				}

				&.active span {
					display: none !important;
				}

				span {
					top: 5px;
					left: 40px;
					font-weight: bold;
				}
			}

			a {
				padding: 4px 15px 4px 10px;
			}

			.fa {
				font-size: 17px;
			}
		}
	}

	.sidebar-menu > li:hover > {
		.treeview-menu, a > span {
			background: transparent;
		}
	}
}
