.row.field-display{
    label{
        font-weight: bold;
        display:block;
        text-align:right;
    }
}
.iframe-switch-detail-wrapper{
    .iframe-switch-label{
        float: left;
        font-weight: bold;
        margin-bottom: 0.1em;
        margin-top: 0.1em;
    }

    label.toggle-switch-wrapper.toggle-ad-iframe {
        margin-left: 7px;
    }
}
