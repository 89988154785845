aside.main-sidebar .sidebar-menu li:not(.submenu-active).active {
    background: $color-primary;

    a, .menu-icon, a {
        color: #fff;
    }
}

.ea-sidebar-width-compact aside.main-sidebar .sidebar-menu li:hover {
    background: $color-primary;

    &:not(.active) a {
        i, span {
            color: #ffffff;
        }
    }

    background: #4d748f;
}

aside.main-sidebar .sidebar-menu li:hover {
    background: $color-primary;

    &:not(.active) a {
        i, span {
            color: #ffffff;
        }
    }
}