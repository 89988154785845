table.datagrid > tbody > tr {

    transition: .15s;

    &:nth-of-type(even).company-inspected-active {
        background-color: rgba(76, 184, 42, 0.27);
    }

    &:nth-of-type(odd).company-inspected-active {
        background-color: rgba(76, 184, 42, 0.15);

        &:hover {
            background-color: rgba(76, 184, 42, 0.1);
        }
    }

    &:nth-of-type(even) {
        &.company-inspected-active:hover {
            background-color: rgba(76, 184, 42, 0.1);
        }

        &.company-iframe-active {
            background-color: rgba(255, 209, 62, 0.27);
        }
    }

    &:nth-of-type(odd).company-iframe-active {
        background-color: rgba(255, 209, 62, 0.15);

        &:hover {
            background-color: #f5f5f5;
        }
    }

    &:nth-of-type(even).company-iframe-active:hover {
        background-color: #f5f5f5;
    }
}