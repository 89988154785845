aside.main-sidebar {
    padding-right: 7px;
    .sidebar-menu {
        padding-top: 5px;
        padding-bottom: 5px;

        li {
            padding: 10px 15px;
            a {
                padding: 4px 15px 4px 7px;
            }

            &:not(.submenu-active).active, &:hover {
                .menu-icon, a {
                    color: var(--color-primary);
                    filter: opacity(100%);
                }

                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                background: #333;

                right: -5px;
                width: calc(100% + 5px);

                box-shadow: var(--box-shadow-lg);
            }

            &:hover {
                right: 0;
                width: 100%;
                @include transition(200ms)
            }

            &:not(.submenu-active).active, {
                box-shadow: var(--box-shadow-lg), 0px 0 12px 0px rgba(255, 255, 255, 0.5);
            }

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sidebar {
        background: #fff;
        background: var(--white);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow-lg);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

body.ea-sidebar-width-normal{
	.main-sidebar{
		min-width: 175px;
	}
}
