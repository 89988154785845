// import the main styling from the jobblitz bundle
@import '../../vendor/jobblitz/jobblitz-bundle/assets/scss/main';

// import the style overrides
@import 'abstract/root-properties';
@import 'abstract/variables';

@import 'components/buttons';
@import 'components/cards';

@import 'page/header';
@import 'page/sidebar';
@import 'page/login';

