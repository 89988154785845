.btn-primary {
    background-color: #333333;
    font-weight: bold;
    color: var(--color-primary);

    &:hover {
        background-color: #333333;
        font-weight: bold;
        color: var(--color-primary);
    }

    &:not(:disabled):not(.disabled) {
        &:active {
            background-color: #333333;
            font-weight: bold;
            color: var(--color-primary);

            &:focus {
                background-color: #333333;
                font-weight: bold;
                color: var(--color-primary);
            }
        }

        &:focus {
            background-color: #333333;
            font-weight: bold;
            color: var(--color-primary);
        }
    }
}

a.statistik-button {
    display: block;
    padding: 15px;
    border: 1px solid;
    border-radius: var(--border-radius);

    &.category {
        color: #337ab7;
        border-color: #337ab7;
    }

    &.campaign {
        color: #5cb85c;
        border-color: #5cb85c;
    }

    &.region {
        color: #d9534f;
        border-color: #d9534f;
    }

    span {
        float: right;
    }
}

//Toggle button
label.toggle-switch-wrapper {

    position: relative;
    display: inline-block;
    width: 44px;
    height: 30px;
    top: -2px;
    margin-bottom: 0;
    margin-right: 6px;

    border-radius: var(--border-radius);
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: var(--border-radius);

        &:before {
            position: absolute;
            content: "";
            height: 22px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: var(--border-radius);
        }
    }


    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: #337ab7;
        }

        &:disabled + .slider {
            opacity: 0.3;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #337ab7;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX(15px);
            -ms-transform: translateX(15px);
            transform: translateX(15px);
        }
    }

    &.toggle-company-inspected {
        input {
            &:checked + .slider {
                background-color: #4CB82A;
            }
        }
    }
}

a.btn.btn-default.btn-detail{

    margin-right: 7px;
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #333333;
    background-color: #333333;
}