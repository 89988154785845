header.main-header {
    background-color: #ffffff;
    border-bottom: 1px solid transparentize($color-primary, 0.8);
}

header.main-header .content-top.navbar-custom-menu .user {
    color: $color-primary;
}

.popover {
	border-radius: var(--border-radius);
	.user-details{
		p.small.text-muted.mb-0 {
			font-size: 13px;
		}
	}
	.user-menu a{
		color: $color-primary;
		&:hover{
			color:transparentize($color-primary, 0.35);
		}
		&:active, &:focus{
			color:transparentize($color-primary, 0.5);
		}
	}
}
