header.main-header {
    background-color: $header-background-color;
    padding: 10px;
    box-shadow: var(--box-shadow-lg);

    #header-logo {
        .logo {
            height: 40px;
            padding-left: 3px;
        }
    }

    .content-top.navbar-custom-menu {
        padding: 10px;

        .user {
            color: #eeeeee;
        }
    }
}

.popover {
    border-radius: var(--border-radius);
	.user-details{
		p.small.text-muted.mb-0 {
			font-size: 13px;
		}
	}
	.user-menu a{
		color: #333;
		&:hover{
			color:transparentize(#333, 0.35);
		}
		&:active, &:focus{
			color:transparentize(#333, 0.5);
		}
	}
}

.content-header:after, .content-header:before{
    content: none;
}
