.datagrid-header-tools .datagrid-search {
    flex: 1;
    max-width: 480px;
    margin-right: 15px;
    border-radius: var(--border-radius);
    // box-shadow: var(--box-shadow-lg);
    box-shadow: 0 2px 5px 0 rgba(43, 45, 80, .08), 0 1px 1.5px 0 rgba(0, 0, 0, .07), 0 1px 2px 0 rgba(0, 0, 0, .08);

    background-color: #fff;
    margin-bottom: 10px;
    transition: box-shadow .08s ease-in,color .08s ease-in;
    &:hover {
        box-shadow: 0 0 0 1px rgba(43, 45, 80, .1), 0 2px 5px 0 rgba(43, 45, 80, .1), 0 3px 9px 0 rgba(43, 45, 80, .08), 0 1px 1.5px 0 rgba(0, 0, 0, .08), 0 1px 2px 0 rgba(0, 0, 0, .08);
    }
}


