#flash-messages {
    background: transparent;
    box-shadow: none;
    padding: 30px;
    position: fixed;
    width: 33%;
    z-index: 999;
    right: 60px;
    top: 15px;

    .alert {
        box-shadow: var(--box-shadow-lg);
        border-radius: var(--border-radius);
        margin-bottom: 7px;
    }
}