td.text-.field-association a {
	color: var(--color-secondary);
}

body#ea-index-Log {

	div.content-panel {
		table {
			td {
				&.field-text {

					span[title="SUCCESS"] {
						color: #10553b;
						background-color: #d2ede3;
						border-color: #c0e5d7;
						padding: 3px 5px;
						font-size: 14px;
						border-radius: 4px;
						font-weight: bold;
					}

					span[title="INFO"] {
						color: #033f5f;
						background-color: #cde4f1;
						border-color: #b9d9eb;
						padding: 3px 5px;
						font-size: 14px;
						border-radius: 4px;
						font-weight: bold;
					}

					span[title="WARNING"] {
						color: #713e0c;
						background-color: #f7e4d1;
						border-color: #f4d9be;
						padding: 3px 5px;
						font-size: 14px;
						border-radius: 4px;
						font-weight: bold;
					}

					span[title="ERROR"] {
						color: #6b1f33;
						background-color: #f5d8e0;
						border-color: #f1c9d3;
						padding: 3px 5px;
						font-size: 14px;
						border-radius: 4px;
						font-weight: bold;
					}
				}
			}
		}
	}
}
