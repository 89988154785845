.chart-js-wrapper {
    position: relative;
    canvas.chart-js.chart-doughnut {
        position: relative;
        z-index: 10;
    }
    .chartjs-tooltip {
        left: 0;
        top: 0;
        font-family: Arial, sans-serif;
        font-style: normal;
        right: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 0;
        height: 100%;
        padding: 0;
        opacity: 1 !important;
        align-items: center;
        color: #333333;
        font-size: 33px !important;
        font-weight: 800 !important;

        p{
            text-align: center;margin-bottom: 0;
            span.label {
                font-size: 22px;
            }
        }
    }
}