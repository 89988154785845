.login-wrapper {

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);
    background: #fff;

    header.main-header {
        border-radius: var(--border-radius);

        display: block;
        box-shadow: none;
        padding-right: 10px;

        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: none;


        #header-logo {
            .logo {
                height: 50px;
                padding: 0;
            }
        }
    }

    .content {
        padding: 10px 20px;
        width: 100%;
        .btn-primary {
            box-shadow: none;
            &:active, &:focus, &:active:focus, &:disabled {
                background-color: #fff;
                color: var(--color-primary);
            }
        }
    }
}
